<template>
  <div class="material" :class="{'page-is-loading': pageIsLoading}">
    <Bread :list="bread"></Bread>
    <div class="material-info">
      <div class="material-info-title">{{materialInfo.title}}</div>
      <div class="material-info-wrap">
        <div class="material-info-left">
          <el-image class="material-info-img" :src="materialInfo.pic" alt="コース画像" fit="contain">
            <div slot="error" class="image-slot">
              <i class="el-icon-picture-outline"></i>
            </div>
          </el-image>
        </div>
        <div class="material-info-right">
          <div class="material-info-item">
            <div class="material-info-item-label">講座</div>
            <div class="material-info-item-value">{{materialInfo.courseName}}</div>
          </div>
          <div class="material-info-item">
            <div class="material-info-item-label">教科</div>
            <div class="material-info-item-value">{{materialInfo.subjectName}}</div>
          </div>
          <div class="material-info-item">
            <div class="material-info-item-label">単元</div>
            <div class="material-info-item-value">{{materialInfo.unitName}}</div>
          </div>
          <div class="material-info-desc">
            {{materialInfo.abstract}}
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="material-apply">{{isLogin?'受講申請はこちらへ。':'ログインして受講申請ください。'}}</div> -->
    <div class="material-detail">
      <div style="margin-bottom:10px">{{materialInfo.detail}}</div>
      <!-- <div class="material-detail-item"
        v-for="(item, index) in materialInfo.detail" :key="index"
      >
        <div class="material-detail-title">{{item.name}}</div>
        <div class="material-detail-content">
          <div class="material-detail-content-item-wrap" v-for="(i, idx) in item.value" :key="idx">
            <div class="material-detail-content-item" v-if="typeof i === 'string'">
              · {{i}}
            </div>
            <div v-else class="material-detail-content-item">
              <div class="material-detail-content-item-title">
                {{`${idx+1}. ${i.name}`}}
              </div>
              <div class="material-detail-content-item-value">
                {{i.value}}
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <div class="material-detail-item">
        <div class="material-detail-title">教材コンテンツ</div>
        <div class="material-detail-content">
          <div class="material-detail-content-item-wrap">
            <div class="material-detail-content-item">既に受講しました。再度受講したい場合、下記のURLにて受講ください。</div>
            <div class="material-detail-content-item">コンテンツ種別: {{materialInfo.content.type}}</div>
            <div v-if="materialInfo.content.isType != '01'">
              <el-link class="material-detail-content-item"
                       href="#" @click.native="download()"
                       style="text-decoration: underline"
              >
                コンテンツURL
              </el-link>
            </div>
            <div v-if="materialInfo.content.isType == '01'">
              <el-link class="material-detail-content-item"
                       :href="materialInfo.content.url" target="_blank"
                       style="text-decoration: underline"
              >
                コンテンツURL
              </el-link>
            </div>
          </div>
          <div v-if="materialInfo.scsStudyStatus != '1'">
            <el-button native-type="button" type="danger" plain @click="goEndTimeUpdate()">教材受講が終了後、押下してください。</el-button>
          </div>
          <div v-if="materialInfo.scsStudyStatus == '1'">
            <el-button native-type="button" type="danger" plain disabled>教材受講が終了しました。</el-button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Bread from '@/components/Bread.vue';
import request from '@/utils/request';
import downloadRequest from '@/utils/downloadRequest';
import loadingMixin from '@/utils/loadingMixin';

export default {
  name: 'Material',
  components: {
    Bread,
  },
  inject: ['isLogin'],
  mixins: [loadingMixin],
  data() {
    return {
      bread: [],
      tableIsLoading: false,
      materialID: '',
      materialInfo: {
        title: '',
        pic: '',
        courseId: '',
        courseName: '',
        subjectId: '',
        subjectName: '',
        unitId: '',
        unitName: '',
        abstract: '',
        detail: '',
        scsStudyStatus: '0',
        // detail: [
        //   {
        //     name: '教材説明００１',
        //     value: [
        //       '例文：論理的思考の重要性、構成されている要素を理解できます。',
        //       '「OJT（on-the-job training）」とは何かが分かる',
        //       'OJT研修の手順を理解できる',
        //     ],
        //   },
        //   // {
        //   //   name: '教材コンテンツ',
        //   //   value: [
        //   //     '既に受講しました。再度受講したい場合、下記のURLにて受講ください。',
        //   //     '下記のURLにて受講ください。',
        //   //     'コンテンツ種別：PDF',
        //   //   ],
        //   // },
        // ],
        content: {
          type: '',
          url: '',
        },
      },
    };
  },
  computed: {
  },
  created() {
    this.materialID = this.$route.params.id;
    request('getMaterialDetail', {
      courseId: this.$route.params.courseId,
      subjectId: this.$route.params.subjectId,
      unitId: this.$route.params.unitId,
      materialId: this.materialID,
    }).then((res) => {
      this.loadingClose();
      this.materialInfo = {
        title: res.teachingMaterialTitle,
        pic: res.teachingMaterialImagePath,
        courseId: this.$route.params.courseId,
        courseName: res.courseTitle,
        subjectId: this.$route.params.subjectId,
        subjectName: res.subjectTitle,
        unitId: this.$route.params.unitId,
        unitName: res.unitName,
        abstract: res.teachingMaterialOutline,
        detail: res.teachingMaterialDetail,
        scsStudyStatus: res.scsStudyStatus,
        content: {
          type: res.contentTypeName,
          isType: res.contentType,
          url: res.contentUrl,
        },
      };
      this.bread = [
        {
          path: '/',
          name: 'トップページ',
        },
        {
          path: `/course/${this.materialInfo.courseId}`,
          name: this.materialInfo.courseName,
        },
        {
          path: `/subject/${this.$route.params.subjectId}/${this.$route.params.courseId}`,
          name: this.materialInfo.subjectName,
        },
        {
          path: `/unit/${this.$route.params.unitId}/${this.$route.params.courseId}/${this.$route.params.subjectId}`,
          name: this.materialInfo.unitName,
        },
        {
          name: this.materialInfo.title,
        },
      ];
    });
  },
  methods: {
    goEndTimeUpdate() {
      request('endTimeUpdate', {
          courseId : this.$route.params.courseId,
          subjectId :  this.$route.params.subjectId,
          unitId :  this.$route.params.unitId,
          materialId :  this.materialID,
        }).then((res) => {
          this.materialInfo.scsStudyStatus = res;
        });
    },
    download(name) {
      downloadRequest('download', this.materialInfo.content.url, {
          courseId : this.$route.params.courseId,
          subjectId :  this.$route.params.subjectId,
          unitId :  this.$route.params.unitId,
          materialId :  this.materialID,
        });
    }
  },
  mounted() {
  },
};
</script>

<style lang="less">
.material {
  margin: 30px 0;

  .material-info {
    margin-bottom: 20px;

    .material-info-title {
      text-align: center;
      font-size: 26px;
      margin-bottom: 60px;
    }
    .material-info-wrap {
      display: flex;

      .material-info-left {
        margin-right: 10px;
        box-sizing: border-box;
        // border: 1px solid #ccc;

        .material-info-img {
          width: 320px;
          min-height: 230px;
          // height: 282px;
          .image-slot {
            min-height: 230px;
          }
        }
      }
      .material-info-right {
        flex: 1;
        display: flex;
        flex-direction: column;
        // border: 1px solid #ccc;
        box-sizing: border-box;

        .material-info-item {
          font-size: 15px;
          // border-bottom: 1px solid #ccc;

          .material-info-item-label {
            padding: 8px;
            width: 180px;
            display: inline-block;
            // background-color: #eee;
          }
          .material-info-item-value {
            padding: 8px;
            display: inline-block;
          }
        }
        .material-info-desc {
          flex: 1;
          padding: 8px;
          font-size: 13px;
        }
      }
    }
  }
  .material-apply {
    background-color: #F1DCDB;
    padding: 20px;
    text-align: center;
    font-weight: bold;
    font-size: 20px;
    margin-bottom: 20px;
    cursor: pointer;
  }
  .material-detail {
    .material-detail-item {
      margin-bottom: 20px;

      .material-detail-title {
        border-bottom: 1px solid rgba(0, 0, 0, 0.12);
        font-size: 18px;
        font-weight: bold;
        padding-bottom: 5px;
      }
      .material-detail-content {
        font-size: 15px;
        padding: 10px 0;

        .material-detail-content-item-wrap {
          .material-detail-content-item {
            margin-bottom: 15px;

            .material-detail-content-item-title {
              margin-bottom: 8px;
            }
            .material-detail-content-item-value {
              font-size: 14px;
              color: #777;
            }
          }
        }
      }
    }
  }
}
@media (max-width: 767px) {
  .material {
    .material-info {
      .material-info-wrap {
        flex-direction: column;
        .material-info-left {
          margin-right: 0;
          text-align: center;
          .material-info-img {
            height: 160px;
          }
        }
        .material-info-right {
          .material-info-item {
            .material-info-item-label {
              width: 80px
            }
          }
        }
      }
    }
  }
}
</style>
